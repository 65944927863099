import React, { useEffect, useState } from "react";

const History = () => {
  const [loaded, setLoaded] = useState(false);
  const [loaded2, setLoaded2] = useState(false);
  const [loaded3, setLoaded3] = useState(false);
  const [loaded4, setLoaded4] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("history-text");
      const element2 = document.getElementById("history-text2");
      const element3 = document.getElementById("history-text3");
      const element4 = document.getElementById("history-text4");
      if (element) {
        const rect = element.getBoundingClientRect();
        const isInViewport =
          rect.top >= 100 && rect.bottom <= window.innerHeight;
        setLoaded(isInViewport);
      }
      if (element2) {
        const rect = element2.getBoundingClientRect();
        const isInViewport =
          rect.top >= 100 && rect.bottom <= window.innerHeight;
        setLoaded2(isInViewport);
      }
      if (element3) {
        const rect = element3.getBoundingClientRect();
        const isInViewport =
          rect.top >= 100 && rect.bottom <= window.innerHeight;
        setLoaded3(isInViewport);
      }
      if (element4) {
        const rect = element4.getBoundingClientRect();
        const isInViewport =
          rect.top >= 100 && rect.bottom <= window.innerHeight;
        setLoaded4(isInViewport);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="history" className="history-container">
      <div className="col-1">
        <p id="history-text" className={`history ${loaded ? "visible" : ""}`}>
          I love solving complex problems and building processes for long-term
          success.
        </p>

        <p id="history-text2" className={`history ${loaded2 ? "visible" : ""}`}>
          Currently at{" "}
          <a href="https://cardknox.com" rel="noopener noreferrer">
            Cardknox
          </a>
          , leading a team of 7 members, including content/technical writers,
          designers, web developers, and administrators.
        </p>

        <p id="history-text3" className={`history ${loaded3 ? "visible" : ""}`}>
          Based in Brooklyn for the last twelve years after graduating from Pace
          University.
        </p>
        <p id="history-text4" className={`history ${loaded4 ? "visible" : ""}`}>
          In my spare time, I enjoy playing chess, finding new music, and
          cooking.
        </p>
      </div>
      <div className="col-2">
        <div className="heading">experience</div>
        <div className="role">
          <div>FIDELITY PAYMENT SERVICES / CARDKNOX</div>
          <div className="role-time">2019 - Current</div>
        </div>
        <div className="role">
          <div>UPPORTUNITY</div>
          <div className="role-time">6 Years</div>
        </div>
        <div className="role">
          <div>MCA</div>
          <div className="role-time">5 Years</div>
        </div>
        <div className="role">
          <div>BUBLUP</div>
          <div className="role-time">2 Years</div>
        </div>
        <div className="heading">certifications</div>
        <div className="role">
          <div>GENERAL ASSEMBLY</div>
          <div className="role-time">Web Development Immersive</div>
        </div>
      </div>
    </div>
  );
};

export default History;
