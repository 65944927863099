import React, { useState, useEffect } from "react";
import instagram from "../img/instagram.png";
import mail from "../img/mail.png";
import linkedin from "../img/linkedin.png";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="menu">
      <div className="header">
        <div className={`logo ${isScrolled ? "scrolled" : ""}`}>
          <a href="https://www.alexvarone.com/">alex varone</a>
        </div>
        <div className={`nav ${isScrolled ? "scrolled" : ""}`}>
          <ul>
            <li>
              <a href="https://www.linkedin.com/in/alexvarone/">linkedin</a>
            </li>
            <li>
              <a href="https://instagram.com/alexvarone">instagram</a>
            </li>
          </ul>
        </div>
        <div className={`contact ${isScrolled ? "scrolled" : ""}`}>
          <ul>
            <li>
              <a href="mailto:hello@alexvarone.com">hello@alexvarone.com</a>
            </li>
          </ul>
        </div>
        <div className="nav-mobile">
          <ul>
            <li>
              <a
                className={`social ${isScrolled ? "scrolled" : ""}`}
                href="https://www.linkedin.com/in/alexvarone/"
              >
                <img src={linkedin} alt="linkedin" />
              </a>
            </li>
            <li>
              <a
                className={`social ${isScrolled ? "scrolled" : ""}`}
                href="https://instagram.com/alexvarone"
              >
                <img src={instagram} alt="instagram" />
              </a>
            </li>
            <li>
              <a
                className={`social ${isScrolled ? "scrolled" : ""}`}
                href="mailto:hello@alexvarone.com"
              >
                <img src={mail} alt="email" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
