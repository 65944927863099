import React, { useEffect, useState } from 'react';

const Intro = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('intro-text');
      if (element) {
        const rect = element.getBoundingClientRect();
        const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;
        setLoaded(isInViewport);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Define the handleClick function
  const handleClick = () => {
    const targetElement = document.getElementById('history');
    if (targetElement) {
      // Scroll the target component into view
      targetElement.scrollIntoView({
        behavior: 'smooth', // Smooth scroll
        block: 'start' // Align to the start of the window
      });
    }
  };

  return (
    <div className="intro-container">
      <div id="intro-text" className={`intro ${loaded ? 'visible' : ''}`}>
        I'm Alex — a Marketing Manager with a focus on developing digital experiences.
      </div>
      <div onClick={handleClick} className={`arrow ${loaded ? 'visible' : ''}`}>
        <div className="arrow-dash"></div>
        <div className="arrow-triangle"></div>
      </div>
    </div>
  );
};

export default Intro;
