import './App.css';
import './styles.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Intro from './components/Intro';
import History from './components/History';
import Ball from './components/Ball'; // This component will be shown on all pages



function App() {
  return (
    <Router>
      <div className="App">
        <div className='container'>
          <Header/>
          <Routes>
            <Route path="/" element={
              <>
                <Intro/>
                <History/>
                {/* <Ball/> is moved outside to render on every page */}
              </>
            }/>
           
          </Routes>
          <Ball/> {/* This will render on every page */}
        </div>
      </div>
    </Router>
  );
}

export default App;