import React, { useState, useEffect } from "react";

const Ball = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [figurePosition, setFigurePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const rect = document.body.getBoundingClientRect();
    setMousePosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    setFigurePosition({
      x: mousePosition.x - figureWidth + 150, // Adjust for figure width
      y: mousePosition.y - figureHeight + 145, // Adjust for figure height
    });
  }, [mousePosition]);

  // Replace figureWidth and figureHeight with the actual dimensions of your figure
  const figureWidth = 300;
  const figureHeight = 300;

  return (
    <div>
      <div
        className="circle"
        style={{
          position: "absolute",
          left: `${figurePosition.x}px`,
          top: `${figurePosition.y}px`,
          width: `${figureWidth}px`,
          height: `${figureHeight}px`,
        }}
      >
        {/* Your figure content goes here */}
      </div>
    </div>
  );
};

export default Ball;
